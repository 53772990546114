import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Hero extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
          <section
            id={`${this.props.id}`}
            className="about"
            style={{ height: this.context.height }}
          >
            {this.particles()}
            <Row>
              <Col md={6} className="content">
                <div className="content-text">
                  <div className="line-text">
                    <h4>A Propos</h4>
                  </div>
                  <h3>Concepteur développeur d’applications</h3>
                  <div className="separator" />
                  <p>
                    Titulaire d'un BTS Informatique (SIO SLAM) Développeur Full
                    Stack, je suis développeur back end et front-end sur
                    différents frameworks. Je cherche aujourd'hui une mission de
                    concepteur développeur d’applications. Développeur
                    back-office de site marchand B to C et B to B. J’ai réalisé
                    plusieurs programmes avec différents langages (Python, PHP)
                    qui ont permis d'assurer la mise à jour et la cohérence des
                    données. Je me suis spécialisé dans l’automatisation des
                    traitements des données.
                  </p>
                  <div className="social social_icons">
                    <FontAwesomeIcon
                      icon={faGithub}
                      className="social_icon"
                      onClick={() =>
                        window.open("https://github.com/sfedor2020")
                      }
                    />
                    {/* <FontAwesomeIcon
                      icon={faTwitter}
                      className="social_icon"
                      onClick={() => window.open("https://www.twitter.com")}
                    /> */}
                    {/* <FontAwesomeIcon
                      icon={faYoutube}
                      className="social_icon"
                      onClick={() => window.open("https://www.youtube.com")}
                    /> */}
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      className="social_icon"
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/in/fedor-salov-2aa1b82b0/"
                        )
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col md={6} className="skills">
                <div className="line-text">
                  <h4>Mes compétences</h4>
                  {/* <h4>Mes Compétences Professionnelles</h4>  */}
                </div>
                <div className="skills-container">
                  <Progress name="Python" value={90} delay={1100} />
                  <Progress name="PHP / Symfony" value={80} delay={1100} />
                  {/* <Progress name="PHP" value={80} delay={1100} /> */}
                  {/* <Progress name="Symfony" value={80} delay={1100} /> */}
                  <Progress name="React JS / Gatsby JS" value={85} delay={1100}/>
                  <Progress name="HTML / JS / CSS" value={80} delay={1100} />
                  <Progress name="SQL / GraphQL" value={80} delay={1100} />
                  <Progress name="Microsoft Azure" value={70} delay={1100} />
                  <Progress name="CMS : Prestashop" value={80} delay={1100} />
                  {/* <Progress name="QT" value={65} delay={1100} /> */}
                  <Progress name="QT/ Batch / Bash / Réseau" value={60} delay={1100}/>
                  {/* <Progress name="Réseau" value={50} delay={1100} /> */}
                  {/* <Progress name="Mise en place de l’environnement technologique de développement" value={80} delay={1100} /> */}
                </div>
              </Col>
            </Row>
          </section>
        );
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 50,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
            }}/>
        )
    }

}

export default Hero