import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import BaffleText from "components/baffle-text";
import AnimationContainer from "components/animation-container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReact,
  faAngular,
  faAws,
  faSymfony,
  faGithub,
  faGithubAlt,
  faGithubSquare,
} from "@fortawesome/free-brands-svg-icons";
import {
  faServer,
  faCloud,
  faCartPlus,
  faBarcode,
  faHistory,
  faImage,
  faImages,
  faCodeBranch,
  faPizzaSlice,
  faQuoteRight,
  faCode,
} from "@fortawesome/free-solid-svg-icons";
import Counter from "components/counter";
import ThemeContext from "../../context";
import "./styles.scss";

class Experience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.show = this.show.bind(this);
  }

  static contextType = ThemeContext;

  show() {
    this.setState({ show: true });
  }

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="services"
        style={{ height: this.context.height }}
      >
        <Row
          className="top"
          style={{
            maxHeight:
              this.context.height !== "auto"
                ? this.context.height * 0.8
                : "inherit",
          }}
        >
          <div className="content">
            <Col md={12}>
              <div className="line-text">
                <h4>Experience</h4>
              </div>
              <div className="heading">
                <BaffleText
                  text="Professionnelle et Scolaire"
                  revealDuration={500}
                  revealDelay={500}
                  parentMethod={this.show}
                  callMethodTime={1100}
                />
              </div>
              <div
                className="services_container"
                style={{
                  minHeight:
                    this.context.height !== "auto"
                      ? this.context.height * 0.6
                      : "inherit",
                }}
              >
                <Container>{this.experience()}</Container>
              </div>
            </Col>
          </div>
        </Row>
        <Row className="bottom">{this.counters()}</Row>
      </section>
    );
  }

  experience() {
    if (this.state.show || this.context.height === "auto") {
      return (
        <Row>
          <Col md={4} className="service">
            <AnimationContainer delay={200} animation="fadeInLeft fast">
              <div className="icon">
                <FontAwesomeIcon icon={faReact} />
              </div>
              <h4>React JS</h4>
              <p>
              React est une bibliothèque JavaScript open-source qui est utilisée pour construire des interfaces utilisateur spécifiquement pour des applications d'une seule page. Elle est utilisée pour gérer la couche d'affichage des applications web et mobiles.
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service border-side">
            <AnimationContainer delay={400} animation="fadeInDown fast">
              <div className="icon">
                <FontAwesomeIcon icon={faImage}/>
              </div>
              <h4>Gatsby JS</h4>
              <p>
              Gatsby est un générateur de site Web statique (SSG) open source basé sur le React et utilisant les technologies Webpack et GraphQL. Il est utilisé pour créer des sites statiques qui sont des applications Web progressives, optimisés pour la vitesse et la sécurité.
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service">
            <AnimationContainer delay={600} animation="fadeInRight fast">
              <div className="icon">
                <FontAwesomeIcon icon={faSymfony} />
              </div>
              <h4>Symfony</h4>
              <p>
                Symfony est un framework de développement PHP “open source” avec
                architecture MVC (Modèle-vue-contrôleur) qui vise à accélérer la
                création et la maintenance des applications web et à remplacer
                les tâches de codage récurrentes.
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service">
            <AnimationContainer delay={800} animation="fadeInLeft fast">
              <div className="icon">
                <FontAwesomeIcon icon={faCloud} className="solid" />
              </div>
              <h4>Microsoft Azure</h4>
              <p>
              Microsoft Azure est la plate-forme applicative en nuage de Microsoft. Son nom évoque le « cloud computing », ou informatique en nuage (l'externalisation des ressources informatiques d'une entreprise vers des datacenters distants). Il s'agit d'une offre d'hébergement (applications et données) et de services (workflow, stockage et synchronisation des données, bus de messages, contacts…).
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service border-side">
            <AnimationContainer delay={1000} animation="fadeInUp fast">
              <div className="icon">
                <FontAwesomeIcon icon={faImages} className="solid" />
              </div>
              <h4>QT</h4>
              <p>
                une API orientée objet et développée en C++, conjointement par
                The Qt Company et Qt Project. Qt offre des composants
                d'interface graphique (widgets), d'accès aux données, de
                connexions réseaux, de gestion des fils d'exécution, d'analyse
                XML, etc.
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service">
            <AnimationContainer delay={1200} animation="fadeInRight fast">
              <div className="icon">
                <FontAwesomeIcon icon={faCartPlus} className="solid" />
              </div>
              <h4>Prestashop</h4>
              <p>
                PrestaShop est un CMS spécialement conçue pour des sites
                Internet e-commerces. Il fonctionne grâce à un système
                modulaire, donc une fois la boutique de base créée, il est
                possible de mettre en place des fonctions supplémentaires : par
                exemple, de nouveaux moyens de paiement, des améliorations
                graphiques etc.
              </p>
            </AnimationContainer>
          </Col>
        </Row>
      );
    }
  }

  counters() {
    if (this.state.show || this.context.height === "auto") {
      return (
        <Container>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faCode}
                value={50}
                text="Dépôts"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faCodeBranch}
                value={1300}
                text="Contribution"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faGithub}
                value={5}
                text="Following"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faGithubSquare}
                value={2}
                text="Followers"
                symbol=""
                duration={3}
              />
            </AnimationContainer>
          </Col>
          {/* <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faGithubSquare}
                value={50000}
                text="Encore Une Exemple"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col> */}
        </Container>
      );
    }
  }
}

export default Experience;
